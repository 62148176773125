import styled from 'styled-components';
import { breakpoint } from '../../../../utils/breakpoints';
import { UbManufacturedStyled } from "./UbManufactured.styled";
import { STNetworkMemberLogoStyled } from "./STNetworkMemberLogo.styled";
export var FooterStyled = styled.footer.withConfig({
  displayName: "footerstyled__FooterStyled",
  componentId: "sc-1lipl9l-0"
})(["", ";position:relative;z-index:1;"], {
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgba(17, 17, 17, var(--tw-bg-opacity))",
  "display": "flex",
  "paddingTop": "4rem",
  "paddingBottom": "0px",
  "justifyContent": "center",
  "overflow": "hidden",
  "@media (min-width: 1024px)": {
    "paddingBottom": "6rem"
  }
});
export var FooterBgStyled = styled.div.withConfig({
  displayName: "footerstyled__FooterBgStyled",
  componentId: "sc-1lipl9l-1"
})(["", " &::before{", " border-radius:0% 165px 0% 0% / 0% 106px 0% 0%;background-color:rgb(174 174 174 / 50%);width:106%;", "{border-radius:0% 575px 0% 0% / 0% 160px 0% 0%;}", "{border-radius:0% 575px 0% 0% / 0% 160px 0% 0%;}}&::after{", " content:'';position:absolute;background-color:rgb(var(--color-white));opacity:0.1;background-color:transparent;top:100%;right:30%;height:8rem;width:30%;border-radius:0% 80% 0% 0% / 0% 100% 0% 0%;box-shadow:1000px 1000px 0 1000px rgb(174 174 174 / 50%);", "{box-shadow:1250px 1000px 0 1000px rgb(174 174 174 / 50%);}", "{top:95.9%;right:25%;height:8rem;width:30%;border-radius:0% 80% 0% 0% / 0% 100% 0% 0%;box-shadow:rgb(174 174 174 / 50%) 1800px 1033px 0 1000px;}}}"], {
  "display": "flex",
  "justifyContent": "center",
  "width": "100%",
  "position": "relative"
}, {
  "content": "'*'",
  "--tw-backdrop-blur": "blur(12px)",
  "backdropFilter": "var(--tw-backdrop-filter)",
  "opacity": "0.1",
  "position": "absolute",
  "width": "100%",
  "height": "100%",
  "zIndex": "0",
  "pointerEvents": "none"
}, breakpoint('lg'), breakpoint('xl'), {
  "content": "'*'",
  "--tw-backdrop-blur": "blur(12px)",
  "backdropFilter": "var(--tw-backdrop-filter)",
  "opacity": "0.1",
  "position": "absolute",
  "width": "100%",
  "height": "100%",
  "zIndex": "0",
  "pointerEvents": "none",
  "display": "none",
  "@media (min-width: 1024px)": {
    "display": "block"
  }
}, breakpoint('4xl'), breakpoint('5xl'));
export var FooterContentStyled = styled.div.attrs({
  className: 'grid'
}).withConfig({
  displayName: "footerstyled__FooterContentStyled",
  componentId: "sc-1lipl9l-2"
})(["", " position:relative;transition:all .3s ease-in-out;", "{max-width:1800px;padding-left:1.25rem;padding-right:1.25rem;}&:before{display:block;content:'';background-image:var(--footerBgImageUrl);background-size:cover;position:absolute;height:37%;width:68%;top:-10%;left:-26%;", "{height:54%;width:46%;top:-15%;left:-13%;}", "{width:33%;left:-12%;}", "{width:33%;left:-3%;}@media (min-width:1400px){height:60%;width:25%;top:-15%;left:-2%;}@media (min-width:2100px){height:60%;width:25%;top:-20%;left:-2%;}", "{height:60%;width:25%;top:-15%;left:-10%;}}a{", "}h4{", "}.branding-block{", ";a{", " padding-bottom:11%;max-width:272px;filter:invert(1);", "{padding-bottom:15%;max-width:310px;}> div{", "}}span{", " ", "}}"], {
  "--tw-text-opacity": "1",
  "color": "rgb(var(--color-white) / var(--tw-text-opacity))",
  "fontWeight": "700",
  "display": "grid",
  "gridTemplateColumns": "repeat(1, minmax(0, 1fr))",
  "gap": "1rem",
  "fontFamily": "HK Nova, sans-serif",
  "paddingLeft": "1.25rem",
  "paddingRight": "1.25rem",
  "paddingTop": "5rem",
  "paddingBottom": "5rem",
  "position": "relative",
  "width": "100%",
  "@media (min-width: 768px)": {
    "gridTemplateColumns": "repeat(12, minmax(0, 1fr))"
  },
  "@media (min-width: 1024px)": {
    "paddingLeft": "5rem",
    "paddingRight": "5rem",
    "paddingTop": "7rem",
    "paddingBottom": "7rem"
  },
  "@media (min-width: 1280px)": {
    "paddingLeft": "9rem",
    "paddingRight": "9rem"
  }
}, breakpoint('4xl'), breakpoint('md'), breakpoint('lg'), breakpoint('xl'), breakpoint('4xl'), {
  "cursor": "pointer"
}, {
  "marginTop": "0px",
  "fontSize": "1rem",
  "lineHeight": "1.5rem",
  "@media (min-width: 1024px)": {
    "fontSize": "1.125rem",
    "lineHeight": "1.75rem"
  }
}, {
  "gridColumn": "span 1 / span 1",
  "display": "block",
  "height": "100%",
  "marginBottom": "0px",
  "@media (min-width: 768px)": {
    "gridColumn": "span 4 / span 4",
    "marginBottom": "1.25rem"
  },
  "@media (min-width: 1024px)": {
    "gridColumn": "span 4 / span 4",
    "marginBottom": "2.5rem"
  },
  "@media (min-width: 1280px)": {
    "gridColumn": "span 3 / span 3"
  }
}, {
  "position": "relative",
  "display": "block"
}, breakpoint('lg'), {
  "position": "absolute",
  "height": "100%"
}, {
  "fontWeight": "400",
  "display": "block",
  "marginTop": "1.25rem",
  "fontSize": "1.125rem",
  "lineHeight": "1.75rem",
  "@media (min-width: 1024px)": {
    "marginTop": "0.75rem"
  }
}, {
  "@media (min-width: 1024px)": {
    "fontSize": "1.25rem",
    "lineHeight": "1.75rem"
  }
});
export var ColRightStyled = styled.div.withConfig({
  displayName: "footerstyled__ColRightStyled",
  componentId: "sc-1lipl9l-3"
})(["", " ", " ", " ", "}"], {
  "gap": "1rem",
  "display": "flex",
  "flexDirection": "column",
  "@media (min-width: 768px)": {
    "gridColumnStart": "1",
    "gridColumnEnd": "5"
  },
  "@media (min-width: 1024px)": {
    "gap": "6rem",
    "flexDirection": "row"
  }
}, {
  "@media (min-width: 768px)": {
    "display": "grid",
    "gridTemplateColumns": "repeat(12, minmax(0, 1fr))"
  }
}, {
  "justifyContent": "flex-end",
  "@media (min-width: 768px)": {
    "gridColumnStart": "5",
    "gridColumnEnd": "13"
  }
}, {
  "justifyContent": "flex-end",
  "@media (min-width: 1024px)": {
    "gridColumnStart": "5",
    "gridColumnEnd": "13"
  }
});
export var ColNewsLetterStyled = styled.div.withConfig({
  displayName: "footerstyled__ColNewsLetterStyled",
  componentId: "sc-1lipl9l-4"
})(["", " ", " ", " ", " ", "{margin-top:-13px;}.alexa-and-google{", " div{", "}p{", "}a{&:first-of-type{margin-right:1rem;}}img{display:initial;", "{width:48%;}", "{width:auto;}}}"], {
  "@media (min-width: 768px)": {
    "gridColumnStart": "1",
    "gridColumnEnd": "9"
  }
}, {
  "@media (min-width: 1024px)": {
    "gridColumnStart": "1",
    "gridColumnEnd": "6"
  }
}, {
  "@media (min-width: 1280px)": {
    "gridColumnStart": "1",
    "gridColumnEnd": "5"
  }
}, {
  "@media (min-width: 1536px)": {
    "gridColumnStart": "1",
    "gridColumnEnd": "4"
  }
}, breakpoint('lg'), {
  "display": "none",
  "@media (min-width: 1024px)": {
    "display": "flex"
  }
}, {
  "margin": "0px"
}, {
  "display": "flex"
}, breakpoint('lg'), breakpoint('xl'));
export var ColMenuStyled = styled.div.withConfig({
  displayName: "footerstyled__ColMenuStyled",
  componentId: "sc-1lipl9l-5"
})(["", " ", " ", "{padding-left:15%;}", "{padding-left:20%;}", "{padding-left:15%;}[class*='cmsBlock-root']{", " [class*='richContent-root-']{&:first-of-type{", "}}}h4{", " color:#F4F4F4;display:flex;align-items:center;a{text-decoration:none;transition:all .3s ease-in-out;&:hover{color:#EABFBD;}}&:after{display:none;font:var(--fa-font-solid);content:'\f105';}svg{margin-top:.25rem;margin-left:.25rem;color:#175EB7;}}ul{", " li{", " ", " color:#B9B9B9;font-size:1rem;span{font-size:1rem !important;}a{font-size:1rem;transition:all .3s ease-in-out;&:hover{color:#EABFBD;}}}}.become-distributor-link{width:100%;display:flex;justify-content:space-between;border-bottom:solid 1px #707070;height:3.5rem;padding-right:.5rem;margin-bottom:1rem;", "{display:block;width:auto;height:auto;border-bottom:none;}}"], {
  "justifyContent": "flex-end",
  "@media (min-width: 768px)": {
    "gridColumnStart": "1",
    "gridColumnEnd": "13",
    "marginTop": "2.5rem"
  },
  "@media (min-width: 1024px)": {
    "marginTop": "0px"
  }
}, {
  "justifyContent": "flex-end",
  "@media (min-width: 1024px)": {
    "gridColumnStart": "6",
    "gridColumnEnd": "9"
  },
  "@media (min-width: 1280px)": {
    "gridColumnStart": "6"
  },
  "@media (min-width: 1536px)": {
    "gridColumnStart": "5",
    "gridColumnEnd": "8"
  }
}, breakpoint('lg'), breakpoint('xl'), breakpoint('2xl'), {
  "margin": "0px"
}, {
  "@media (min-width: 768px)": {
    "marginRight": "1rem"
  },
  "@media (min-width: 1024px)": {
    "marginRight": "0px"
  }
}, {
  "marginTop": "0px",
  "fontSize": "1rem",
  "lineHeight": "1.5rem",
  "@media (min-width: 1024px)": {
    "fontSize": "1.125rem",
    "lineHeight": "1.75rem"
  }
}, {
  "listStyleType": "none",
  "padding": "0px",
  "marginTop": "0px"
}, {
  "padding": "0px",
  "marginTop": "0.25rem",
  "marginBottom": "0.25rem",
  "display": "block"
}, {
  "fontWeight": "400",
  "fontFamily": "Avenir, sans-serif",
  "display": "block"
}, breakpoint('md'));
export var ColConnectStyled = styled.div.withConfig({
  displayName: "footerstyled__ColConnectStyled",
  componentId: "sc-1lipl9l-6"
})(["", " ", " ", " ", "{display:grid;grid-template-columns:repeat(6,minmax(0,1fr));grid-template-rows:1fr;grid-gap:1rem;}", "{display:block;padding-left:15%;}", "{padding-left:0;}", ",", "{", "}.contact-buttons{", ";", " ", " ", "{grid-area:1/1/2/4;justify-content:flex-start;}", "{justify-content:center;}}h4{", ";margin-bottom:1.125rem;", "{grid-area:1/4/2/7;margin-bottom:0;}", "{margin-bottom:1.125rem;}}.contact-buttons{ul{", "}}.payment-options-container{", ";", "{grid-area:1/4/2/7;margin-top:2.5rem;}", "{margin-top:0;}h4{margin-top:2.5rem;", "{margin-top:3.5rem;margin-bottom:1rem;}", "{margin-top:2.5rem;margin-bottom:1.125rem;}}& + ", "{", " & + ", "{", "}}}.payment-options{", " > div{", "}ul{", " li{", " img{", " width:2.5rem !important;height:2.5rem !important;}}}}"], {
  "@media (min-width: 768px)": {
    "gridColumnStart": "1",
    "gridColumnEnd": "13",
    "marginTop": "0px"
  }
}, {
  "@media (min-width: 1024px)": {
    "gridColumnStart": "9",
    "gridColumnEnd": "13"
  }
}, {
  "@media (min-width: 1536px)": {
    "gridColumnStart": "8",
    "gridColumnEnd": "11"
  }
}, breakpoint('md'), breakpoint('lg'), breakpoint('2xl'), UbManufacturedStyled, STNetworkMemberLogoStyled, {
  "justifyContent": "center",
  "marginTop": "1rem",
  "marginBottom": "2rem",
  "@media (min-width: 768px)": {
    "display": "none"
  }
}, {
  "flex": "1 1 0%",
  "display": "flex",
  "alignItems": "center",
  "justifyContent": "center",
  "width": "100%"
}, {
  "@media (min-width: 768px)": {
    "textAlign": "left"
  },
  "@media (min-width: 1024px)": {
    "display": "block"
  }
}, {
  "@media (min-width: 1024px)": {
    "marginBottom": "1rem"
  },
  "@media (min-width: 1536px)": {
    "marginBottom": "4rem"
  }
}, breakpoint('md'), breakpoint('lg'), {
  "textAlign": "center",
  "@media (min-width: 768px)": {
    "textAlign": "left"
  }
}, breakpoint('md'), breakpoint('lg'), {
  "marginTop": "0px",
  "@media (min-width: 1024px)": {
    "display": "flex",
    "flexDirection": "column"
  },
  "@media (min-width: 1280px)": {
    "display": "grid"
  }
}, {
  "display": "none",
  "@media (min-width: 768px)": {
    "display": "block"
  },
  "@media (min-width: 1536px)": {
    "display": "none"
  }
}, breakpoint('md'), breakpoint('lg'), breakpoint('md'), breakpoint('lg'), UbManufacturedStyled, {
  "display": "none",
  "justifyContent": "flex-start",
  "@media (min-width: 1024px)": {
    "display": "flex"
  },
  "@media (min-width: 1536px)": {
    "display": "none"
  }
}, STNetworkMemberLogoStyled, {
  "display": "none",
  "justifyContent": "flex-start",
  "@media (min-width: 1024px)": {
    "display": "flex"
  },
  "@media (min-width: 1536px)": {
    "display": "none"
  }
}, {
  "@media (min-width: 1024px)": {
    "marginBottom": "5rem"
  }
}, {
  "marginLeft": "0px",
  "marginRight": "0px"
}, {
  "display": "flex",
  "listStyleType": "none",
  "padding": "0px",
  "justifyContent": "center",
  "margin": "0px",
  "@media (min-width: 768px)": {
    "justifyContent": "flex-start"
  }
}, {
  "marginLeft": "0.25rem",
  "marginRight": "0.25rem",
  "@media (min-width: 768px)": {
    "marginLeft": "0px",
    "marginRight": "1.5rem"
  }
}, {
  "margin": "0px"
});
export var SocialLinksStyled = styled.div.withConfig({
  displayName: "footerstyled__SocialLinksStyled",
  componentId: "sc-1lipl9l-7"
})(["", "{grid-area:1/4/2/7;margin-top:2.5rem;}", "{margin-top:0;}> div{", "}.social-icons-wrapper{> div{flex-direction:row !important;justify-content:center !important;", "{justify-content:flex-start !important;}[class*='text-root']{&:last-child{a{margin-right:0;}}}a{font-size:0;width:2.5rem;height:2.5rem;background:#F0E2E2;border-radius:50%;display:flex;align-items:center;justify-content:center;transition:all .3s ease-in-out;margin-right:1.5rem;&:hover{background:#EABFBD;}svg{font-size:1.188rem;color:#1F1F1F;}}.fb-social-icon{a{align-items:flex-end;&:before{display:none;font:var(--fa-font-brands);content:\"\\f39e\";}svg{font-size:1.7rem;margin-left:4px;}}}.tumblr-social-icon{a{&:before{display:none;font:var(--fa-font-brands);content:\"\\f173\";}}}.instagram-social-icon{a{&:before{display:none;font:var(--fa-font-brands);content:\"\\f16d\";}}}.tiktok-social-icon{a{&:before{display:none;font:var(--fa-font-brands);content:\"\\e07b\";}}}}}"], breakpoint('md'), breakpoint('lg'), {
  "marginLeft": "0px",
  "marginRight": "0px"
}, breakpoint('md'));
export var ColOptionsStyled = styled.div.withConfig({
  displayName: "footerstyled__ColOptionsStyled",
  componentId: "sc-1lipl9l-8"
})(["", " ", " ", " margin:0;.need-more-material-container{[class*='text-root']{}}", ",", "{", "}h4{", ";margin-bottom:1.125rem;}.payment-options-container{", ";}.payment-options{", " > div{", "}ul{", " li{", " max-width:2.5rem;max-height:2.5rem;img{", " width:2.5rem !important;height:2.5rem !important;}}}}.need-more-material-container{", " div{", "}p,h4{", "}a{", " text-decoration:none;&:hover{text-decoration:underline;}}}"], {
  "@media (min-width: 768px)": {
    "gridColumnStart": "7",
    "gridColumnEnd": "13"
  }
}, {
  "@media (min-width: 1024px)": {
    "gridColumnStart": "11",
    "gridColumnEnd": "13"
  }
}, {
  "@media (min-width: 1536px)": {
    "gridColumnStart": "11",
    "gridColumnEnd": "13"
  }
}, UbManufacturedStyled, STNetworkMemberLogoStyled, {
  "display": "none",
  "@media (min-width: 768px)": {
    "display": "flex"
  },
  "@media (min-width: 1024px)": {
    "display": "none"
  },
  "@media (min-width: 1536px)": {
    "display": "flex"
  }
}, {
  "textAlign": "center",
  "@media (min-width: 768px)": {
    "textAlign": "left"
  }
}, {
  "display": "block",
  "@media (min-width: 768px)": {
    "display": "none"
  },
  "@media (min-width: 1536px)": {
    "display": "block"
  }
}, {
  "@media (min-width: 1024px)": {
    "marginBottom": "5rem"
  }
}, {
  "marginLeft": "0px",
  "marginRight": "0px"
}, {
  "display": "flex",
  "listStyleType": "none",
  "padding": "0px",
  "justifyContent": "center",
  "margin": "0px",
  "@media (min-width: 768px)": {
    "justifyContent": "flex-start"
  }
}, {
  "marginLeft": "0.5rem",
  "marginRight": "0.5rem",
  "@media (min-width: 768px)": {
    "marginLeft": "0px",
    "marginRight": "0.5rem"
  }
}, {
  "margin": "0px"
}, {
  "display": "none"
}, {
  "margin": "0px"
}, {
  "marginBottom": "0.25rem"
}, {
  "fontWeight": "400",
  "display": "block",
  "fontSize": "1.125rem",
  "lineHeight": "1.75rem",
  "--tw-text-opacity": "1",
  "color": "rgba(102, 102, 102, var(--tw-text-opacity))"
});
export var ColCopyrightStyled = styled.div.withConfig({
  displayName: "footerstyled__ColCopyrightStyled",
  componentId: "sc-1lipl9l-9"
})(["", " div,p{", ";font-weight:400;}"], {
  "gridColumnStart": "1",
  "@media (min-width: 768px)": {
    "gridColumnEnd": "13",
    "marginTop": "2rem"
  }
}, {
  "width": "100%",
  "display": "block",
  "textAlign": "center",
  "@media (min-width: 768px)": {
    "textAlign": "left"
  }
});
export var FooterEffectStyled = styled.div.withConfig({
  displayName: "footerstyled__FooterEffectStyled",
  componentId: "sc-1lipl9l-10"
})(["", " max-width:1680px;&:before,&:after{", " ", "{margin-top:-38px;}}&:before{", " content:'';background:rgb(111,174,255);background:radial-gradient( circle,rgba(111,174,255,1) 0%,rgba(17,17,17,0) 50% );width:300px;height:300px;}&:after{", " content:'';background:rgb(253,208,208);background:radial-gradient( circle,rgba(253,208,208,1) 0%,rgba(17,17,17,0) 50% );width:500px;height:500px;}"], {
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgba(17, 17, 17, var(--tw-bg-opacity))",
  "opacity": "0.5",
  "position": "relative",
  "overflow": "hidden"
}, {
  "position": "absolute"
}, breakpoint('lg'), {
  "display": "block",
  "opacity": "0.25"
}, {
  "display": "block",
  "opacity": "0.25"
});
export var FooterOuterBlock = styled.div.withConfig({
  displayName: "footerstyled__FooterOuterBlock",
  componentId: "sc-1lipl9l-11"
})(["background:#111111;ul{margin:0 auto;padding:1.875rem 0;", ";", ";li{font-family:'Avenir',\"sans-serif\";color:#BEBEBE;list-style:none;", ";a{color:#BEBEBE;display:block;text-decoration:none;}}}"], {
  "display": "flex",
  "justifyContent": "center",
  "@media (min-width: 768px)": {
    "justifyContent": "flex-start"
  },
  "@media (min-width: 1024px)": {
    "justifyContent": "flex-start"
  },
  "@media (min-width: 1280px)": {
    "justifyContent": "flex-start"
  }
}, {
  "paddingLeft": "1.25rem",
  "paddingRight": "1.25rem",
  "@media (min-width: 1024px)": {
    "paddingLeft": "5rem",
    "paddingRight": "5rem"
  },
  "@media (min-width: 1280px)": {
    "paddingLeft": "9rem",
    "paddingRight": "9rem"
  }
}, {
  "marginLeft": "1rem",
  "marginRight": "1rem",
  "fontSize": "0.75rem",
  "lineHeight": "1rem",
  "@media (min-width: 768px)": {
    "marginRight": "3rem",
    "marginLeft": "0px",
    "fontSize": "1rem",
    "lineHeight": "1.5rem"
  },
  "@media (min-width: 1024px)": {
    "marginRight": "3rem",
    "marginLeft": "0px",
    "fontSize": "1rem",
    "lineHeight": "1.5rem"
  },
  "@media (min-width: 1280px)": {
    "marginRight": "3rem",
    "marginLeft": "0px",
    "fontSize": "1rem",
    "lineHeight": "1.5rem"
  }
});