import styled from 'styled-components';
export var NeedHelpWrapperStyled = styled.div.withConfig({
  displayName: "NeedHelpstyled__NeedHelpWrapperStyled",
  componentId: "sc-rg17m1-0"
})(["", ";"], {
  "paddingLeft": "1.25rem",
  "paddingRight": "1.25rem",
  "@media (min-width: 1024px)": {
    "paddingLeft": "5rem",
    "paddingRight": "5rem"
  },
  "@media (min-width: 1280px)": {
    "paddingLeft": "9rem",
    "paddingRight": "9rem"
  }
});