var capitalizeFirstLetter = string => {
  return string.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
};
var fractionToFloat = fraction => {
  if (fraction === '0') {
    return 0;
  }
  var split = fraction === null || fraction === void 0 ? void 0 : fraction.split('/');
  var up = parseInt(split[0], 10);
  var down = parseInt(split[1], 10);
  if (up === 0 && down === 0) {
    return 0;
  }
  return parseInt(split[0], 10) / parseInt(split[1], 10);
};

// https://stackoverflow.com/questions/95727/how-to-convert-floats-to-human-readable-fractions/681534#681534
var decimalToFraction = value => {
  var df = 1;
  var top = 1;
  var bottom = 1;
  while (df !== value) {
    if (df < value) {
      top += 1;
    } else {
      bottom += 1;
      top = parseInt(value * bottom);
    }
    df = top / bottom;
  }
  return top + '/' + bottom;
};
var getInches = value => {
  var decimalPart = value - Math.floor(value);
  var integralPart = value - decimalPart;
  return {
    wholes: integralPart.toString(),
    eighths: decimalPart !== 0 ? decimalToFraction(decimalPart) : '0/0'
  };
};
var dynamicStatusClassGen = (eighths, wholes, defaultClass) => {
  if (eighths && wholes) {
    return 'active ' + defaultClass;
  }
  if (eighths || wholes) {
    return 'dashed ' + defaultClass;
  }
  return defaultClass;
};

// TODO: refactor later. used the old implementation. implemented by Dotsquare team
var addressFormatter = (addressObject, countries) => {
  var ShouldBeComponent = {
    street_number: ['street_number'],
    postal_code: ['postal_code'],
    street: ['street_address', 'route'],
    province: ['administrative_area_level_1', 'administrative_area_level_2', 'administrative_area_level_3', 'administrative_area_level_4', 'administrative_area_level_5'],
    city: ['locality', 'sublocality', 'sublocality_level_1', 'sublocality_level_2', 'sublocality_level_3', 'sublocality_level_4'],
    country: ['country']
  };
  var address = {
    street_number: '',
    street: '',
    city: '',
    postal_code: '',
    state: '',
    country: '',
    province: ''
  };
  addressObject.forEach(component => {
    for (var shouldBe in ShouldBeComponent) {
      if (ShouldBeComponent[shouldBe].indexOf(component.types[0]) !== -1) {
        if (shouldBe === 'country') {
          address[shouldBe] = component.short_name;
        } else {
          address[shouldBe] = component.long_name;
        }
      }
    }
  });

  // Fix the shape to match our schema
  address.street = address.street_number + ' ' + address.street;
  delete address.street_number;
  if (countries) {
    var filteredCountry = countries.find(country => country.value === address.country);
    if (filteredCountry) {
      address.country = filteredCountry.value;
    }
    if (filteredCountry.available_regions) {
      if (address.province) {
        var filteredState = filteredCountry.available_regions.find(region => region.name === address.province);
        if (filteredState) address.state = filteredState.id;
      } else {
        address.state = '';
      }
    } else {
      address.state = address.province;
    }
  } else {
    address.state = address.province;
  }
  delete address.province;
  return address;
};
var getUrl = () => window.location.pathname + window.location.search;
var getLocalStorageValue = parameterNames => {
  for (var parameterName of parameterNames) {
    var storedValue = localStorage.getItem(parameterName);
    if (storedValue) {
      return storedValue;
    }
  }
  return null;
};
var removeLocalStorageValue = parameterNames => {
  for (var parameterName of parameterNames) {
    if (localStorage.getItem(parameterName)) {
      localStorage.removeItem(parameterName);
      break;
    }
  }
};
export { capitalizeFirstLetter, fractionToFloat, decimalToFraction, getInches, dynamicStatusClassGen, addressFormatter, getUrl, getLocalStorageValue, removeLocalStorageValue };