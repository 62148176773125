import styled from 'styled-components';
import { breakpoint } from '../../../../utils/breakpoints';
export var StyledHeader = styled.div.withConfig({
  displayName: "Headerstyled__StyledHeader",
  componentId: "sc-1b8dxte-0"
})(["", " transition:all .3s ease-in-out;position:sticky;top:-", ";&.stickyDefault{position:sticky;top:0;}&.stickyPromo{top:0;}header{", " ", "{", "}", "{", "}svg{stroke:white;}}.toolbar{", " ", "}.logoContainer{", " ", " max-width:151px;", "{max-width:180px;}", "{max-width:216px;}div{", "}[class*='image-placeholder-']{background-color:transparent;}}.logoContainerCheckout{", " ", " max-width:151px;margin:0 auto;", "{max-width:180px;margin:0 auto;}", "{max-width:216px;margin:0 auto;}div{", "}}.secondaryActionss{", "}}"], {
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgba(17, 17, 17, var(--tw-bg-opacity))",
  "zIndex": "20"
}, props => props.pHeight + 'px', {
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgba(17, 17, 17, var(--tw-bg-opacity))",
  "display": "flex",
  "height": "auto",
  "justifyContent": "center",
  "paddingLeft": "1.25rem",
  "paddingRight": "1.25rem",
  "top": "0px",
  "position": "sticky",
  "width": "100%",
  "zIndex": "10",
  "--tw-text-opacity": "1",
  "color": "rgb(var(--color-white) / var(--tw-text-opacity))"
}, breakpoint('lg'), {
  "paddingLeft": "5rem",
  "paddingRight": "5rem"
}, breakpoint('xl'), {
  "paddingLeft": "9rem",
  "paddingRight": "9rem"
}, {
  "borderWidth": "0px",
  "alignContent": "center",
  "columnGap": "1rem",
  "display": "flex",
  "alignItems": "center",
  "justifyContent": "space-between",
  "width": "100%",
  "height": "3.5rem"
}, {
  "@media (min-width: 1024px)": {
    "height": "5rem",
    "columnGap": "2rem"
  }
}, {
  "gridColumnStart": "1",
  "justifySelf": "center",
  "height": "4rem"
}, {
  "@media (min-width: 1024px)": {
    "gridColumnEnd": "3",
    "gridColumnStart": "1",
    "justifySelf": "start",
    "marginRight": "2rem",
    "gridRowStart": "1"
  }
}, breakpoint('md'), breakpoint('lg'), {
  "height": "100%"
}, {
  "gridColumnStart": "1",
  "justifySelf": "center",
  "height": "4rem"
}, {
  "@media (min-width: 1024px)": {
    "gridColumnEnd": "3",
    "gridColumnStart": "1",
    "justifySelf": "start",
    "marginRight": "2rem",
    "gridRowStart": "1"
  }
}, breakpoint('md'), breakpoint('lg'), {
  "height": "100%"
}, {
  "gridColumnStart": "6",
  "gridColumnEnd": "13",
  "display": "grid",
  "gridAutoFlow": "column",
  "alignItems": "center",
  "justifyItems": "end",
  "justifySelf": "end"
});
export var StyledNavTriggerContainer = styled.div.withConfig({
  displayName: "Headerstyled__StyledNavTriggerContainer",
  componentId: "sc-1b8dxte-1"
})(["", ""], {
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgba(17, 17, 17, var(--tw-bg-opacity))",
  "display": "flex",
  "alignItems": "center",
  "@media (min-width: 1024px)": {
    "display": "none"
  }
});
export var StyledSkipToContent = styled.a.withConfig({
  displayName: "Headerstyled__StyledSkipToContent",
  componentId: "sc-1b8dxte-2"
})(["position:absolute;background-color:#175eb7;color:white;padding:0.5rem 1rem;", ";z-index:50;top:-2.5rem;text-align:center;width:10rem;height:2.5rem;display:flex;justify-content:center;align-items:center;transition:all .3s ease-in-out;&:hover{background-color:#1459AF;}&:focus-visible{top:0;outline:auto;text-decoration:underline;}"], {
  "fontFamily": "HK Nova, sans-serif",
  "fontWeight": "700"
});